import React, { useState } from 'react';
import Modal from 'react-modal';
import { MdClose } from '@react-icons/all-files/md/MdClose';
import { StaticImage } from 'gatsby-plugin-image';
import { useForm, ValidationError } from '@formspree/react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { DateTime } from 'luxon';

const customStyles = {
    content: {
        margin: 'auto auto',
        inset: 40,
        backgroundColor: '#EE4D9B',
        color: '#ffffff',
        maxWidth: '66rem',
        height: '41rem',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0,0.8)',
    },
};

const customMobileStyles = {
    content: {
        margin: 'auto auto',
        inset: 0,
        backgroundColor: '#EE4D9B',
        color: '#ffffff',
        width: '100vw',
        height: '100vh',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0,0,0,0.8)',
    },
};

const now = new Date();
const twoMonthsAheadDate = DateTime.fromJSDate(new Date(now.getFullYear(), now.getMonth(), 1)).plus({
    month: 2,
    day: -1,
});

const LandingPopup = ({ isOpen, onClose, code }) => {
    const defaultValues = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        address: '',
        size: '',
        code,
    };
    const [values, setValues] = useState(defaultValues);
    const breakpoints = useBreakpoint();

    const handleOnChange = (e: { target: { name: any; value: any } }) =>
        setValues({ ...values, [e.target.name]: e.target.value });

    const [submissionState, handleSubmit] = useForm('xleazoob');
    const [showToaster, setShowToaster] = useState(true);

    const [expireDate] = useState<string>(twoMonthsAheadDate.toLocaleString(DateTime.DATE_FULL));

    if (submissionState.succeeded) {
        setTimeout(() => setShowToaster(false), 6000);
    }

    const modalContent = showToaster && (
        <div className="absolute bottom-8 right-8">
            <div className="flex items-center bg-green-500 border-l-4 border-green-700 py-2 px-3 shadow-md mb-2">
                <div className="text-green-500 rounded-full bg-white mr-3">
                    <svg
                        width="1.8em"
                        height="1.8em"
                        viewBox="0 0 16 16"
                        className="bi bi-check"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z"
                        />
                    </svg>
                </div>
                <div className="text-white max-w-xs">Thank you! We will be in touch.</div>
            </div>
        </div>
    );

    const mainContent = (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel=""
            style={breakpoints.sm ? customMobileStyles : customStyles}
        >
            <MdClose onClick={onClose} className="absolute right-4 top-4 text-2xl cursor-pointer" />
            <StaticImage
                src="../images/logo-stacked-white.png"
                alt="logo"
                width={100}
                placeholder="blurred"
                className="absolute left-4 top-4 sm:left-8 sm:top-8"
            />
            {/* <StaticImage src="../images/mote.png" alt="mote" height={480} placeholder="none" className="absolute right-12 top-16 hidden md:visible"/> */}
            <div className="mt-12 sm:mt-16">
                <h1 className="text-2xl sm:text-6xl md:text-8xl mb-2 ml-8 sm:mb-8">
                    Free Trial<sup>*</sup>
                </h1>
                <hr className="border-1 border-solid" />
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4 sm:p-8 sm:px-16 md:mr-16 text-sm modal-font font-light">
                    <div>
                        <ul className="list-disc">
                            <li>
                                Register your farm now and be one of the first farms in New Zealand to harness the
                                incredible power of the Farmote System
                            </li>
                            <li className="mt-4">
                                You'll save time, increase profits, and receive the first month to trial the system
                                absolutely free“
                            </li>
                            <li className="mt-4">
                                We'll soon be in touch to discuss how our system can work for you and to organise the
                                FREE setup and installation!
                            </li>
                            <li className="sm:mt-4 hidden sm:visible">
                                Simply fill in your details here, and we™ll be in contact soon
                            </li>
                            <li className="mt-4">
                                After the trial period, the subscription is only $5/ha per month up to 300ha, then $1/ha
                                per month for every hectare over 300ha
                            </li>
                            <li className="mt-4">Offer only available in the South Island (from Oamaru to Waiau)</li>
                        </ul>
                    </div>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="grid sm:grid-cols-2 gap-2.5 poppins">
                                <input
                                    type="text"
                                    name="firstName"
                                    required
                                    value={values.firstName}
                                    placeholder="FIRST NAME"
                                    className="p-3 rounded-md bg-pink-400 placeholder-white"
                                    onChange={handleOnChange}
                                />
                                <ValidationError
                                    prefix="*FirstName"
                                    field="firstName"
                                    errors={submissionState.errors}
                                    className="text-gray-800 italic"
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    placeholder="LAST NAME"
                                    className="p-3 rounded-md bg-pink-400 placeholder-white"
                                    onChange={handleOnChange}
                                />
                                <input
                                    type="text"
                                    name="phone"
                                    value={values.phone}
                                    placeholder="PHONE"
                                    className="p-3 rounded-md bg-pink-400 placeholder-white"
                                    onChange={handleOnChange}
                                />
                                <input
                                    type="email"
                                    id="email"
                                    required
                                    name="email"
                                    value={values.email}
                                    placeholder="EMAIL"
                                    className="p-3 rounded-md bg-pink-400 placeholder-white"
                                    onChange={handleOnChange}
                                />
                                <ValidationError
                                    prefix="*Email"
                                    field="email"
                                    errors={submissionState.errors}
                                    className="text-gray-800 italic"
                                />
                                <input
                                    type="text"
                                    name="address"
                                    required
                                    value={values.address}
                                    placeholder="ADDRESS"
                                    className="p-3 sm:col-span-2 rounded-md bg-pink-400 placeholder-white"
                                    onChange={handleOnChange}
                                />
                                <ValidationError
                                    prefix="*Address"
                                    field="address"
                                    errors={submissionState.errors}
                                    className="text-gray-800 italic"
                                />
                                <input
                                    type="text"
                                    name="size"
                                    value={values.size}
                                    placeholder="SIZE OF FARM (ha)"
                                    className="p-3 rounded-md bg-pink-400 placeholder-white"
                                    onChange={handleOnChange}
                                />
                                <input
                                    type="text"
                                    name="code"
                                    value={values.code}
                                    placeholder="PROMO CODE"
                                    className="p-3 rounded-md bg-pink-400 placeholder-white"
                                    onChange={handleOnChange}
                                />
                                <input type="text" name="_gotcha" className="hidden" />
                                <button
                                    className="sm:col-span-2 justify-self-end p-2 bg-gray-600 w-40 rounded-xl normal-case text-sm"
                                    type="submit"
                                    disabled={submissionState.submitting}
                                >
                                    Register Farm
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <hr className="border-1 border-solid" />
                <div className="grid md:grid-cols-2 sm:grid-cols-1 px-8 pt-2">
                    <div className="text-xl">Offer expires {expireDate}</div>
                    <div className="text-xs modal-font">
                        *Conditions apply. All prices exclude GST. Devices (Motes) are included with the subscription
                    </div>
                </div>
            </div>
        </Modal>
    );

    return (
        <>
            {submissionState.succeeded && modalContent}
            {!submissionState.succeeded && mainContent}
        </>
    );
};

export default LandingPopup;
