import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu';
import LandingPopup from '../components/landingPopup';
import { useQueryParam, StringParam } from 'use-query-params';
import { Helmet } from 'react-helmet';
import { useFlags } from 'gatsby-plugin-launchdarkly';

function Header({ siteTitle }) {
    const [isExpanded, toggleExpansion] = useState(false);
    const [openLandingPopup, setOpenLadingPopup] = useState(false);
    const [code] = useQueryParam('code', StringParam);
    const { showFaqPage } = useFlags();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{siteTitle}</title>
            </Helmet>
            <LandingPopup
                isOpen={openLandingPopup}
                onClose={() => setOpenLadingPopup(false)}
                code={code}
            ></LandingPopup>
            <nav className="fixed z-50 w-full opacity-95 top-0">
                <section
                    className="text-center poppins bg-yellow-200 p-2 cursor-pointer"
                    onClick={() => setOpenLadingPopup(true)}
                >
                    We have launched in the South Island! <span className="underline">Click here</span> to register your
                    farm and we will be in touch soon
                </section>
                <section className="flex flex-wrap items-center justify-between p-6 mb-6 bg-white">
                    <div className="flex items-center flex-shrink-0 mr-6 text-white">
                        <a href="/" className="text-xl font-semibold tracking-tight">
                            <StaticImage src="../images/logo.png" alt="logo" width={240} placeholder="blurred" />
                        </a>
                    </div>
                    <div className="block lg:hidden">
                        <AiOutlineMenu onClick={() => toggleExpansion(!isExpanded)} />
                    </div>
                    <div
                        className={`${isExpanded ? `block` : `hidden`} w-full block lg:flex lg:items-center lg:w-auto`}
                    >
                        <div className="text-sm">
                            <a
                                href="/#how-it-works"
                                className="block mt-4 mr-4 text-pink-500 lg:inline-block lg:mt-0 hover:font-bold"
                            >
                                How It Works
                            </a>
                            <a
                                href="/#team"
                                className="block mt-4 mr-4 text-pink-500 lg:inline-block lg:mt-0 hover:font-bold"
                            >
                                Our Team
                            </a>
                            <a
                                href="/#contact"
                                className="block mt-4 mr-4 text-pink-500 lg:inline-block lg:mt-0 hover:font-bold"
                            >
                                Contact Us
                            </a>
                            {showFaqPage && (
                                <a
                                    href="/faq"
                                    className="block mt-4 mr-4 text-pink-500 lg:inline-block lg:mt-0 hover:font-bold"
                                >
                                    FAQ
                                </a>
                            )}
                            <a
                                href="/#contact"
                                className="block mt-4 mr-4 text-pink-500 lg:inline-block lg:mt-0 hover:font-bold"
                            >
                                Send A Message
                            </a>
                            <a
                                href="https://portal.farmote.com"
                                rel="noreferrer"
                                target="_blank"
                                className="block mt-4 mr-4 text-pink-500 lg:inline-block lg:mt-0 hover:font-bold"
                            >
                                Login
                            </a>
                            {/* <Link
                to={`/blog`}
                className="block mt-4 mr-4 text-pink-500 lg:inline-block lg:mt-0 hover:text-white">
                Blog
              </Link> */}
                        </div>
                        <button
                            onClick={() => setOpenLadingPopup(true)}
                            className="inline-block px-4 py-2 mt-4 text-sm leading-none bg-pink-500 text-white border border-pink-500 rounded hover:border-pink-500 hover:bg-white hover:text-pink-500 lg:mt-0"
                        >
                            Register Farm
                        </button>
                    </div>
                </section>
            </nav>
        </>
    );
}

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
